/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `IMMEDIATE` - Immediate
 * * `1_2_MONTHS` - One To Two Months
 * * `3_5_MONTHS` - Three To Five Months
 * * `6_PLUS_MONTHS` - Six Plus Months
 * * `NOT_APPLICABLE` - Not Applicable
 */
export enum TimelineEnum {
    IMMEDIATE = 'IMMEDIATE',
    _1_2_MONTHS = '1_2_MONTHS',
    _3_5_MONTHS = '3_5_MONTHS',
    _6_PLUS_MONTHS = '6_PLUS_MONTHS',
    NOT_APPLICABLE = 'NOT_APPLICABLE',
}
