import { captureException, withScope } from "@sentry/browser";
import {
  type BuyerQualificationSessionParams,
  type EventProperties,
  trackBuyerQualificationSubmit,
  trackHeapEvent,
} from "./tracking";

import {
  ApiService,
  type ContactEmailSupplierRequest,
  type CreateBuyerQualificationSessionRequest,
  type InteractionTypeEnum,
  OpenAPI,
  type PatchedUserStateRequest,
  QualificationTypeEnum,
  type SubjectTypeEnum,
} from "../generated";
import { getCSRFToken, getProjectId, getRequestID } from "./";
import { handleError as handleGeneratedError } from "./generatedApi";
import { heapSessionId, heapUserId, onHeapInitialize } from "./heap";

// Since we are migrating to autogenerated typescript clients, we need to still support fetching with CSRF tokens.
// Our autogenerated typescript client, openapi-typescript-codegen, does not use CSRF, we have to override
// the global config object's headers here.
OpenAPI.HEADERS = async () => ({ "X-CSRFToken": getCSRFToken() || "" });

// biome-ignore lint/suspicious/noExplicitAny: At some point these should be specifically typed, but use this as an intermediary
export type AnyObject = Record<string, any>;

/**
 * Systematically handles error responses, sending them to sentry.
 * Return true if there was an error to handle, false otherwise.
 **/
export function handleError(
  response: Response,
  logParams: {
    logToSentry: boolean;
    log400ErrorsToSentry: boolean;
    onLogToSentry?: (error: string) => void;
  } = {
    logToSentry: true,
    log400ErrorsToSentry: true,
  }
) {
  if (response.ok || response.status === 200) return false;

  if (response.status === 400 && !logParams.log400ErrorsToSentry) return true;

  if (logParams.logToSentry) {
    withScope((scope) => {
      scope.setFingerprint([
        response.url?.split("?")[0],
        String(response.status),
      ]);
      response.text().then((text) => {
        let errorString = response.status.toString();
        if (response.status !== 500) {
          // 500 errors give entire HTML pages as text,
          // so don't include that in the error message
          errorString += `: ${text}`;
        }
        logParams.onLogToSentry?.(errorString);

        captureException(new Error(errorString));
      });
    });
  }
  return true;
}

export const getErrorMessage = async (errorResponse: Response) => {
  let fullErrorValue = "";
  const body = await errorResponse.json();
  for (const [key, value] of Object.entries(body)) {
    const error = `There is a problem with ${key}: ${value}\n`;
    fullErrorValue += error;
  }
  return fullErrorValue;
};

export async function downloadRemoteFileContents(remoteUrl: string) {
  const response = await fetch(remoteUrl);
  if (handleError(response)) {
    throw new Error(response.statusText);
  }
  return response.arrayBuffer();
}

// Django API

export async function postContactEmailSupplier(
  body: ContactEmailSupplierRequest
) {
  return ApiService.apiV1ContactEmailSupplierCreate({
    ...body,
    heapUserId,
    heapSessionId,
    searchLogId: getRequestID(),
  });
}

export function fetchWithCSRFToken(
  url: string,
  { method, headers, ...options }: RequestInit = {}
) {
  const token = getCSRFToken();
  const requestHeaders: HeadersInit & { "X-CSRFToken"?: string } = {
    ...(headers || {}),
  };
  if (token) requestHeaders["X-CSRFToken"] = token;

  return fetch(url, {
    ...(options || {}),
    method: method || "GET",
    headers: requestHeaders,
  });
}

export function postSignup(form: FormData) {
  return fetchWithCSRFToken("/accounts/signup", {
    body: form,
    method: "POST",
  });
}

export function postLogin(form: FormData) {
  return fetchWithCSRFToken("/accounts/login", {
    body: form,
    method: "POST",
  });
}

export function postSendMagicLink(email: string) {
  return fetchWithCSRFToken("/accounts/send-login-magic-link", {
    body: JSON.stringify({ email }),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
}

export function postSetPassword(password: string) {
  return fetchWithCSRFToken("/accounts/set-password", {
    body: JSON.stringify({ password }),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
}

export function postResetPassword(form: FormData) {
  return fetchWithCSRFToken("/accounts/password/send-reset-link", {
    body: form,
    method: "POST",
  });
}

export function postLogout(form: FormData) {
  return fetchWithCSRFToken("/accounts/logout", {
    body: form,
    method: "POST",
  });
}

export function postSignupSupplier(form: FormData) {
  return fetchWithCSRFToken("/accounts/signup-supplier", {
    body: form,
    method: "POST",
  });
}

export function postSocialSignup(form: FormData) {
  return fetchWithCSRFToken("/accounts/social/signup", {
    body: form,
    method: "POST",
  });
}

export function postConfirmEmail(email: string) {
  const form = new FormData();
  form.append("email", email);
  form.append("action_send", "");
  return fetchWithCSRFToken("/accounts/email", {
    body: form,
    method: "POST",
  });
}

export function getUserDiversityCertifications() {
  return fetchWithCSRFToken("/api/v1/users/me/diversity-certifications/");
}

export function getUserEmailVerified() {
  return ApiService.apiV1UsersEmailVerifiedRetrieve("me");
}

export function patchUserState(data: PatchedUserStateRequest) {
  return ApiService.apiV1UsersUserStatesPartialUpdate("me", "me", data);
}

export function getSupplierContracts({
  supplierId,
  page,
  pageSize,
  sortByLocation = false,
}: {
  supplierId: number;
  page: number;
  pageSize: number;
  sortByLocation: boolean;
}) {
  return fetchWithCSRFToken(
    `/api/v1/suppliers/${supplierId}/contracts/?page=${page}&page_size=${pageSize}&sort_by_location=${sortByLocation}`
  );
}

export function getSupplierExclusions(handle: string) {
  return fetchWithCSRFToken(`/api/v1/supplier-edit/${handle}/exclusions/`);
}

export function createSupplierExclusion(
  handle: string,
  body: { buyerLeadAgencyId: Maybe<string>; key: Maybe<string> }
) {
  return fetchWithCSRFToken(`/api/v1/supplier-edit/${handle}/exclusions/`, {
    body: JSON.stringify(body),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
}

export function deleteSupplierExclusion(handle: string, id: number) {
  return fetchWithCSRFToken(
    `/api/v1/supplier-edit/${handle}/exclusions/${id}`,
    {
      method: "DELETE",
    }
  );
}

export function patchSupplierProfile(handle: string, body: AnyObject) {
  return fetchWithCSRFToken(`/api/v1/supplier-edit/${handle}/`, {
    body: JSON.stringify(body),
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function addSupplierContact(body: AnyObject, handle: string) {
  return fetchWithCSRFToken(`/api/v1/supplier-edit/${handle}/contacts/`, {
    body: JSON.stringify(body),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
}

export function patchSupplierContact(
  body: AnyObject,
  id: number,
  handle: string
) {
  return fetchWithCSRFToken(`/api/v1/supplier-edit/${handle}/contacts/${id}/`, {
    body: JSON.stringify(body),
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
  });
}

export function deleteSupplierContact(id: number, handle: string) {
  return fetchWithCSRFToken(`/api/v1/supplier-edit/${handle}/contacts/${id}/`, {
    method: "DELETE",
  });
}

export function addSupplierLocation(body: AnyObject, handle: string) {
  return fetchWithCSRFToken(`/api/v1/supplier-edit/${handle}/locations/`, {
    body: JSON.stringify(body),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
}

export function patchSupplierLocation(
  body: AnyObject,
  id: number,
  handle: string
) {
  return fetchWithCSRFToken(
    `/api/v1/supplier-edit/${handle}/locations/${id}/`,
    {
      body: JSON.stringify(body),
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
    }
  );
}

export function deleteSupplierLocation(id: number, handle: string) {
  return fetchWithCSRFToken(
    `/api/v1/supplier-edit/${handle}/locations/${id}/`,
    {
      method: "DELETE",
    }
  );
}

export async function postUserInteraction(
  {
    context,
    ...data
  }: {
    interactionType: InteractionTypeEnum;
    subjectType: SubjectTypeEnum;
    subjectId: string;
    supplier?: number;
    contract?: string;
    context?: Record<string, string | boolean | number>;
  },
  heapEvent: { name: string; properties: EventProperties }
) {
  if (!data.subjectId) {
    captureException(new Error("subjectId is required"), {
      extra: {
        subjectId: data.subjectId,
        interactionType: data.interactionType,
        subjectType: data.subjectType,
      },
    });
  }

  // This call should be fire and forget, but because we rely on this API for reporting,
  // errors are important to track.
  let id = null;
  if (!heapUserId) await onHeapInitialize;

  const requestID = getRequestID();
  const projectId = getProjectId();
  try {
    const interaction = await ApiService.apiV1UserInteractionCreate({
      ...data,
      heapUserId,
      heapSessionId,
      // Always augment search log id so we can trace interactions to the originating search.
      searchLog: requestID || heapEvent.properties.requestID,
      context: context ?? {},
    });
    id = interaction.id;
  } catch (err) {
    handleGeneratedError(err);
  }
  trackHeapEvent(
    heapEvent.name,
    {
      // Always provide a requestID and projectId, but allow it to be overridden by manually provided properties.
      requestID,
      projectId,
      ...heapEvent.properties,
      userInteractionId: id,
    },
    { autoStringify: true }
  );
}

export function patchSupplierContract(
  data: FormData,
  supplierId: number,
  docid: string
) {
  return fetchWithCSRFToken(
    `/api/v1/suppliers/${supplierId}/contracts/${docid}/`,
    {
      method: "PATCH",
      body: data,
    }
  );
}

export function postSupplierContractUpload(
  data: FormData,
  supplierHandle: string
) {
  return fetchWithCSRFToken(
    `/api/v1/suppliers/${supplierHandle}/contract-upload`,
    {
      method: "POST",
      body: data,
    }
  );
}

export function getProjectItems(projectId: string) {
  return fetchWithCSRFToken(`/api/v1/projects/${projectId}/items/`);
}

export function fetchSupplierViewAnalytics(handle: string) {
  return fetchWithCSRFToken(`/api/v1/suppliers/${handle}/view-analytics`);
}

export function postSupplierProductListUpload(
  supplierId: number,
  uploadFormData: FormData
) {
  return fetchWithCSRFToken(
    `/api/v1/suppliers/${supplierId}/upload-product-list`,
    {
      method: "POST",
      body: uploadFormData,
    }
  );
}

export async function postBuyerQualificationSession(
  params: BuyerQualificationSessionParams
) {
  const payload: CreateBuyerQualificationSessionRequest = {
    meetsCriteria: params.meetsCriteria,
    qualificationType: params.qualificationType,
    supplierId: params.supplierId,
    // If there is no search log, pass in a null ID instead of an empty string.
    searchLogId: params.searchLogId || null,
    daysActive: params.daysActive,
  };
  try {
    // Supplier share sessions are the only ones that require a Heap user ID.
    if (
      !heapUserId &&
      params.qualificationType === QualificationTypeEnum.SUPPLIER_SHARE
    ) {
      await onHeapInitialize;
    }
    await ApiService.apiV1BuyerQualificationSessionsCreate({
      ...payload,
      heapUserId,
    });
    trackBuyerQualificationSubmit(params);
  } catch (err) {
    handleGeneratedError(err);
  }
}
