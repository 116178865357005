import { useAtomValue } from "jotai";
import pluralize from "pluralize";

import { useEffect } from "react";
import { ApiService } from "../../generated";
import useRefreshSavedSuppliers from "../../hooks/useRefreshSavedSuppliers";
import useRequestID from "../../hooks/useRequestID";
import { projectContextState, savedSuppliersAtom } from "../../jotai/projects";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  CardContainer,
  CardContainerVariant,
  Typography,
} from "../../library";
import { goToURL } from "../../utils";
import { pageNavigationSourceTypes } from "../../utils/enums";
import { getMessageFromProjectUrl } from "../../utils/format";
import { handleError } from "../../utils/generatedApi";
import { trackMessageSupplierFromProject } from "../../utils/tracking";
import { SavedSuppliersList } from "../Pill/SavedSuppliersList";

export default function SavedSuppliersSidebar() {
  const requestID = useRequestID();
  const projectContext = useAtomValue(projectContextState);
  const savedSuppliers = useAtomValue(savedSuppliersAtom);
  const refreshSavedSuppliers = useRefreshSavedSuppliers();

  useEffect(() => {
    refreshSavedSuppliers();
  }, [refreshSavedSuppliers]);

  if (!projectContext?.id) return null;

  async function onDelete(supplierId: number) {
    if (!projectContext?.id) return;
    try {
      await ApiService.apiV1ProjectsSuppliersDestroy(
        projectContext.id,
        supplierId.toString()
      );

      refreshSavedSuppliers();
    } catch (err) {
      handleError(err);
    }
  }

  return (
    <CardContainer className="p-4 gap-4" variant={CardContainerVariant.PRIMARY}>
      <Typography
        size="lg"
        color="brand.boldest.enabled"
        className="font-homeHeader"
      >
        Saved {pluralize("supplier", savedSuppliers.length)} (
        {savedSuppliers.length})
      </Typography>
      <SavedSuppliersList
        suppliers={savedSuppliers}
        pageNavigationSource={pageNavigationSourceTypes.REQUEST_SEARCH}
        onDelete={onDelete}
      />
      <Button
        className="w-fit"
        size={ButtonSizes.SMALL}
        theme={ButtonThemes.PRIMARY_DARK}
        disabled={!savedSuppliers.length}
        onClick={() => {
          trackMessageSupplierFromProject({
            projectId: projectContext.id,
            requestID,
            supplierIds: savedSuppliers.map(({ supplier }) => supplier.id),
          });
          goToURL(
            getMessageFromProjectUrl({
              projectId: projectContext.id,
              requestID,
            })
          );
        }}
      >
        Request {pluralize("quote", savedSuppliers.length)}
      </Button>
    </CardContainer>
  );
}
