import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { ProjectTaskStatusEnum } from "../../../generated";
import {
  projectConstantsAtom,
  projectContextState,
  updateTaskStatusAtom,
} from "../../../jotai/projects";
import {
  CardContainer,
  CardContainerVariant,
  Checkbox,
  Typography,
} from "../../../library";
import { useEditProjectModal } from "../../../modals/ProjectModals/EditProjectModal";
import { getSentenceCase } from "../../../utils";

export default function ProjectOverviewTasks() {
  const [projectContext, setProjectContext] = useAtom(projectContextState);
  const { title } = useAtomValue(projectConstantsAtom);
  const updateTaskStatus = useSetAtom(updateTaskStatusAtom);
  const showModal = useEditProjectModal();

  if (!projectContext) return null;

  // If there's a valid project without a purchase request, show the classic Projects experience
  if (!projectContext.purchaseRequest) {
    return (
      <CardContainer
        className="p-6 gap-4"
        variant={CardContainerVariant.SECONDARY}
      >
        <Typography variant="headline" size="sm" emphasis>
          {title} overview
        </Typography>
        {projectContext.description ? (
          <Typography>{projectContext.description}</Typography>
        ) : (
          <Typography
            className="cursor-pointer"
            color="brand.bold.enabled"
            size="sm"
            underline
            onClick={() => {
              showModal({
                project: projectContext,
                onComplete: (p) =>
                  setProjectContext((prev) => ({ ...prev, ...p })),
              });
            }}
          >
            Add description
          </Typography>
        )}
      </CardContainer>
    );
  }

  // If there's a purchase request, but no selected option, hide this section
  if (!projectContext.purchaseRequest.selectedOption) return null;

  return (
    <CardContainer
      className="p-6 gap-4"
      variant={CardContainerVariant.SECONDARY}
    >
      <Typography variant="headline" size="sm" emphasis>
        {getSentenceCase(projectContext.purchaseRequest.selectedOption.title)}{" "}
        checklist
      </Typography>
      {projectContext.tasks.map(({ id, description, status }) => {
        const completed = status === ProjectTaskStatusEnum.COMPLETED;
        return (
          <Checkbox
            key={id}
            name={description}
            checked={completed}
            // biome-ignore lint/security/noDangerouslySetInnerHtml: This is used to render markdown templates as html.
            label={<div dangerouslySetInnerHTML={{ __html: description }} />}
            labelSize="sm"
            dataTestId={`task-${id}`}
            onChange={() => {
              updateTaskStatus(id, {
                status: completed
                  ? ProjectTaskStatusEnum.NOT_STARTED
                  : ProjectTaskStatusEnum.COMPLETED,
              });
            }}
          />
        );
      })}
    </CardContainer>
  );
}
