import clsx from "clsx";

import { captureMessage } from "@sentry/browser";
import {
  ApiService,
  type CreatePurchaseIntentRequest,
} from "../../../../generated";
import { CardContainer, CardContainerVariant } from "../../../../library";
import { goToURL } from "../../../../utils";
import { elevationClass } from "../../../../utils/designTokens";
import { getSearchPath } from "../../../../utils/format";
import { handleError } from "../../../../utils/generatedApi";
import { getProjectDetailURL } from "../../../Project/utils";
import { PurchaseIntentForm } from "../../../PurchaseIntentForm";

export default function PurchaseIntentCard() {
  async function onSubmit(values: CreatePurchaseIntentRequest) {
    try {
      const response = await ApiService.apiV1PurchaseIntentsCreate(values);
      if (!response.projectId) {
        captureMessage("No project ID returned from purchase intent creation");
        goToURL(
          getSearchPath({
            query: values.query || "",
            queryZip: "",
            analyticsSearchSource: "purchase-intent-card",
          }),
          {},
          false
        );
        return;
      }

      goToURL(getProjectDetailURL(response.projectId), {}, false);
    } catch (err) {
      handleError(err);
    }
  }

  return (
    <CardContainer
      variant={CardContainerVariant.SECONDARY}
      className={clsx(
        "analytics-purchase-intent-card p-6 w-full rounded-6",
        elevationClass["elevation-2"]
      )}
    >
      <PurchaseIntentForm onSubmit={onSubmit} />
    </CardContainer>
  );
}
