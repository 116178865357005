/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `DECIDED` - Decided
 * * `OPEN` - Open
 */
export enum SupplierDecisionEnum {
    DECIDED = 'DECIDED',
    OPEN = 'OPEN',
}
