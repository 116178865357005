import clsx from "clsx";
import { useAtomValue } from "jotai";
import _get from "lodash/get";
import { Fragment, type ReactElement, type ReactNode, useMemo } from "react";

import {
  type ContractHit,
  type FrontendTag,
  MatchLevelEnum,
  type MatchResult,
  type RelevantContractSupplier,
  SupplierConnectionSourceEnum,
} from "../../generated";
import useIsDebug from "../../hooks/useIsDebug";
import useProjectId from "../../hooks/useProjectId";
import useRequestID from "../../hooks/useRequestID";
import {
  searchTypeAtom,
  useAddRecentSuccessfulSearch,
} from "../../jotai/search";
import { profileTypeState, userZipState } from "../../jotai/user";
import { Tooltip } from "../../library";
import {
  ContractLeadSubtitle,
  getContractUrl,
} from "../../shared/ContractBase";
import BaseCard from "../../shared/ContractBase/BaseCard";
import BorderedCardSection from "../../shared/ContractBase/BorderedCardSection";
import SupplierDetailComponent from "../../shared/ContractBase/SupplierDetailComponent";
import getTags from "../../shared/ContractBase/getTags";
import type { CTA } from "../../shared/ContractBase/types";
import { goToURL } from "../../utils";
import { type BgColor, bgColorClass } from "../../utils/colors";
import { CONTRACT_CARDS_MAX_SUPPLIERS_TO_SHOW } from "../../utils/constants";
import { parseDate } from "../../utils/date";
import {
  LoginWallTriggers,
  ProfileType,
  ViewContractRankCTA,
  pageNavigationSourceTypes,
} from "../../utils/enums";
import {
  getMessageSupplierUrl,
  truncateAroundTargetWord,
} from "../../utils/format";
import { FlexibleCardMatch } from "./FlexibleCardMatch";
import type {
  TrackContractCardClickFn,
  TrackContractCardClickFnData,
} from "./types";
import {
  matchesForKey,
  styleSearchResponseText,
  useResultSupplierOfferings,
} from "./utils";

interface SingleSupplierCardProps {
  hit: ContractHit;
  subtitle?: ReactNode;
  analyticsClass: string;
  ctas: CTA[];
  diversityPreferences: Maybe<string>[];
  contractTags: ReactNode[];
  supplierTags: ReactNode[];
  onClick: () => void;
  trackSerpClick: () => void;
  bgColor?: BgColor;
}

function SingleSupplierCard({
  hit,
  subtitle,
  analyticsClass,
  ctas,
  contractTags,
  supplierTags,
  onClick,
  trackSerpClick,
  bgColor = "neutral.subtlest.enabled",
}: SingleSupplierCardProps) {
  const offerings = useResultSupplierOfferings(hit.relevantSuppliers[0]);

  return (
    <BaseCard
      title={hit.title}
      subtitle={subtitle}
      className={clsx(analyticsClass, _get(bgColorClass, bgColor))}
      onClick={onClick}
      ctas={ctas}
      tags={contractTags}
      trackSerpClick={trackSerpClick}
    >
      <BorderedCardSection title={`Suppliers (1 of ${hit.numSuppliers || 1})`}>
        <SupplierDetailComponent
          supplierId={hit.supplierId}
          supplierName={hit.supplierDisplayName}
          matchTier={hit.matchTier}
          supplierOfferings={offerings}
          tags={supplierTags}
          supplierLogoUrl={hit.supplierLogoUrl}
          productHits={hit.relevantSuppliers[0].productHits}
        />
      </BorderedCardSection>
      <FlexibleCardMatch
        hit={hit}
        showingProducts={hit.relevantSuppliers[0].productHits?.length > 0}
      />
    </BaseCard>
  );
}

interface SearchCardProps {
  hit: ContractHit;
  cardAnalyticsClass: string;
  ctaAnalyticsClass: string;
  query: string;
  trackSerpClick: TrackContractCardClickFn;
  diversityPreferences?: Maybe<string>[];
  bgColor?: BgColor;
}

export function SearchCard({
  hit,
  cardAnalyticsClass,
  ctaAnalyticsClass,
  query,
  trackSerpClick,
  diversityPreferences = [],
  bgColor = "neutral.subtlest.enabled",
}: SearchCardProps) {
  const requestID = useRequestID();
  const projectId = useProjectId();
  const userZip = useAtomValue(userZipState);
  const profileType = useAtomValue(profileTypeState);
  const searchType = useAtomValue(searchTypeAtom);
  const addRecentSuccessfulSearch = useAddRecentSuccessfulSearch();
  const expirationDate = parseDate(hit.expirationTimestamp);
  const isDebug = useIsDebug();

  const { contractTagElements } = getTags({
    contractTagData: hit.contractTags,
    expiration_ts: hit.expirationTimestamp,
    expiration_date: expirationDate,
    matchTier: hit.matchTier,
    blaState: hit.buyerLeadAgencyState,
    blaType: hit.buyerLeadAgencyType,
    blaRank: hit.buyerLeadAgencyRank,
    filterScore: hit.RankingInfo.filters,
    semanticScore: hit.RankingInfo.semanticScore,
    proBoost: hit.RankingInfo.proBoost,
    productBoost: hit.RankingInfo.productBoost,
    geoBoost: hit.RankingInfo.geoBoost,
    scaledBoost: hit.RankingInfo.scaledBoost,
    contractQualityBoost: hit.RankingInfo.contractQualityBoost,
    localSupplierBoost: hit.RankingInfo.localSupplierBoost,
    supplierConfirmedStatesServedBoost:
      hit.RankingInfo.supplierConfirmedStatesServedBoost,
    supplierConfirmedAgencyTypesServedBoost:
      hit.RankingInfo.supplierConfirmedAgencyTypesServedBoost,
    supplierAgencyServedBoost: hit.RankingInfo.supplierAgencyServedBoost,
    supplierResponsivenessBoost: hit.RankingInfo.supplierResponsivenessBoost,
    supplierPrimaryOfferingBoost: hit.RankingInfo.supplierPrimaryOfferingBoost,
    isCooperative: hit.cooperativeLanguage ?? false,
    isDebug,
    transparent: true,
    size: "md",
  });

  let subtitle = (
    <ContractLeadSubtitle
      coop={hit.cooperativeAffiliation}
      bla={hit.buyerLeadAgency}
      isCooperative={hit.cooperativeLanguage}
    />
  );
  if (isDebug && hit.RankingInfo.semanticContext) {
    subtitle = (
      <Tooltip
        className="max-w-88"
        info={hit.RankingInfo.semanticContext.replaceAll(", ", "\n")}
        placement="top"
      >
        {subtitle}
      </Tooltip>
    );
  }

  function handleContractClick(data: {
    docid: string;
    supplierId: number;
    supplierHandle: string;
    semanticScore: number;
    displayTag?: string[];
    displayTagCopy?: string[];
  }) {
    addRecentSuccessfulSearch(query);
    goToURL(
      getContractUrl({
        solicitationId: hit.solicitationId,
        docid: data.docid,
        query,
        queryZip: userZip,
        pageNavigationSource: pageNavigationSourceTypes.SEARCH,
        requestID,
        projectId,
        ctaType: ViewContractRankCTA.VIEW_CONTRACT,
        searchType,
      })
    );
  }

  const handleContactSupplierClick = (data: {
    docid: string;
    supplierId: number;
    supplierHandle: string;
    semanticScore: number;
    displayTag?: string[];
    displayTagCopy?: string[];
  }) => {
    goToURL(
      getMessageSupplierUrl({
        handle: data.supplierHandle,
        query,
        zip: userZip,
        requestID,
        projectId,
        messageSupplierSource: SupplierConnectionSourceEnum.CONTRACT_SERP,
        ctaType: ViewContractRankCTA.MESSAGE_SUPPLIER,
        searchType,
      })
    );
  };

  function handleSerpClick(
    data: Omit<TrackContractCardClickFnData, "ctaType">,
    ctaType: ViewContractRankCTA
  ) {
    trackSerpClick({ ...data, ctaType });
  }

  function generateCTAs(data: {
    docid: string;
    supplierId: number;
    supplierHandle: string;
    semanticScore: number;
    displayTag?: string[];
    displayTagCopy?: string[];
  }): CTA[] {
    const viewContractCta: CTA = {
      styling: "secondary",
      text: "View contract",
      ctaAnalyticsClass,
      onClick: () => handleContractClick(data),
      trackSerpClick: () =>
        handleSerpClick(data, ViewContractRankCTA.VIEW_CONTRACT),
    };
    if (profileType !== ProfileType.SUPPLIER) {
      return [
        {
          styling: "tertiary",
          text: "Request quote",
          ctaAnalyticsClass: "analytics-serp-contact-supplier",
          onClick: () => handleContactSupplierClick(data),
          trackSerpClick: () =>
            handleSerpClick(data, ViewContractRankCTA.MESSAGE_SUPPLIER),
          loginWallTrigger: LoginWallTriggers.CONTRACT_SEARCH_CONTRACT_CLICK,
          loginWallTriggerId: data.docid,
        },
        viewContractCta,
      ];
    }
    return [viewContractCta];
  }

  if (hit.relevantSuppliers?.length === 1) {
    const supplierHasDiversityOverlap =
      hit.relevantSuppliers[0].supplierDiversityCertificationIds?.some(
        (diversityId) => diversityPreferences.includes(diversityId)
      );

    const { supplierTagElements, tagVariantList, tagCopyList } = getTags({
      contractTagData: hit.contractTags,
      supplierTagData: hit.relevantSuppliers?.[0]?.supplierTags || [],
      matchesDiversity: supplierHasDiversityOverlap,
      expiration_ts: hit.expirationTimestamp,
      expiration_date: expirationDate,
      isCooperative: hit.cooperativeLanguage,
      transparent: true,
      size: "md",
    });

    const data = {
      docid: hit.docid,
      supplierId: hit.supplierId,
      supplierHandle: hit.supplierHandle,
      semanticScore: hit.RankingInfo.semanticScore,
      displayTag: tagVariantList,
      displayTagCopy: tagCopyList,
    };

    return (
      <SingleSupplierCard
        hit={hit}
        subtitle={subtitle}
        bgColor={bgColor}
        analyticsClass={clsx(cardAnalyticsClass, ctaAnalyticsClass)}
        ctas={generateCTAs(data)}
        onClick={() => handleContractClick(data)}
        trackSerpClick={() =>
          handleSerpClick(data, ViewContractRankCTA.VIEW_CONTRACT)
        }
        diversityPreferences={diversityPreferences}
        contractTags={contractTagElements}
        supplierTags={supplierTagElements}
      />
    );
  }

  const { tagVariantList, tagCopyList } = getTags({
    contractTagData: hit.contractTags,
    supplierTagData: hit.relevantSuppliers?.[0]?.supplierTags || [],
    expiration_ts: hit.expirationTimestamp,
    expiration_date: expirationDate,
    isCooperative: hit.cooperativeLanguage,
  });

  const data = {
    docid: hit.docid,
    supplierId: hit.supplierId,
    supplierHandle: hit.supplierHandle,
    semanticScore: hit.RankingInfo.semanticScore,
    displayTag: tagVariantList,
    displayTagCopy: tagCopyList,
  };

  const titleMatches = matchesForKey(hit.HighlightResult, "contractTitle");

  const titleText =
    titleMatches.length > 0 ? getTitleText(titleMatches) : hit.title;

  const getSupplierTags = (
    supplierTagData: FrontendTag[],
    matchesDiversity: boolean
  ) =>
    getTags({
      contractTagData: hit.contractTags,
      supplierTagData,
      matchesDiversity,
      expiration_ts: hit.expirationTimestamp,
      expiration_date: expirationDate,
      isCooperative: hit.cooperativeLanguage,
      transparent: true,
      size: "md",
    });

  return (
    <BaseCard
      title={titleText}
      subtitle={subtitle}
      className={clsx(
        cardAnalyticsClass,
        ctaAnalyticsClass,
        _get(bgColorClass, bgColor)
      )}
      onClick={() => handleContractClick(data)}
      trackSerpClick={() =>
        handleSerpClick(data, ViewContractRankCTA.VIEW_CONTRACT)
      }
      tags={contractTagElements}
    >
      <BorderedCardSection
        title={`Suppliers (${hit.relevantSuppliers.length} of ${hit.numSuppliers})`}
      >
        {hit.relevantSuppliers
          ?.slice(0, CONTRACT_CARDS_MAX_SUPPLIERS_TO_SHOW)
          .map((relevantSupplier) => {
            const hasDiversityOverlap =
              relevantSupplier.supplierDiversityCertificationIds?.some(
                (diversityId) => diversityPreferences.includes(diversityId)
              );
            const {
              supplierTagElements,
              tagVariantList: supplierDisplayTag,
              tagCopyList: supplierDisplayTagCopy,
            } = getSupplierTags(
              relevantSupplier.supplierTags,
              hasDiversityOverlap
            );
            const ctas = generateCTAs({
              docid: relevantSupplier.docid,
              supplierId: relevantSupplier.supplierId,
              supplierHandle: relevantSupplier.supplierHandle,
              semanticScore: relevantSupplier.RankingInfo.semanticScore,
              displayTag: supplierDisplayTag,
              displayTagCopy: supplierDisplayTagCopy,
            });
            return (
              <SupplierDetailCard
                key={relevantSupplier.supplierId}
                matchTier={hit.matchTier}
                supplier={relevantSupplier}
                tags={supplierTagElements}
                ctas={ctas}
              />
            );
          })}
      </BorderedCardSection>
      <FlexibleCardMatch
        hit={hit}
        showingProducts={hit.relevantSuppliers[0]?.productHits?.length > 0}
      />
    </BaseCard>
  );
}

function SupplierDetailCard({
  matchTier,
  supplier,
  tags,
  ctas,
}: {
  matchTier: string;
  supplier: RelevantContractSupplier;
  tags: ReactElement[];
  ctas: CTA[];
}) {
  const offerings = useResultSupplierOfferings(supplier);

  // Only show products that are associated with this supplier
  const products = useMemo(
    () =>
      supplier.productHits.filter((product) =>
        product.supplierIds.includes(supplier.supplierId)
      ),
    [supplier]
  );

  const supplierId = supplier.supplierId;
  return (
    <SupplierDetailComponent
      supplierId={supplierId}
      supplierName={supplier.supplierDisplayName}
      matchTier={matchTier}
      supplierLogoUrl={supplier.supplierLogoUrl}
      supplierOfferings={offerings}
      tags={tags}
      ctas={ctas}
      productHits={products}
    />
  );
}

function getTitleText(titleMatches: MatchResult[]) {
  if (titleMatches.length === 0) {
    return null;
  }

  const maxWords = 1000; // don't limit, as this'll truncate the title
  const maxCharacters = 1000; // don't limit, as this'll truncate the title

  const scopeElements = titleMatches.slice(0, 2).map((match) => {
    let styledValueText = <span key={match.value}>{match.value}</span>;
    if (match.matchLevel !== MatchLevelEnum.SEMANTIC) {
      const value = truncateAroundTargetWord(
        match.value,
        "<em>",
        maxWords,
        maxCharacters
      );
      styledValueText = styleSearchResponseText(value);
    }
    return styledValueText;
  });

  // join the scopeElements React nodes with a semicolon between them
  return scopeElements.reduce((acc, el, ix) => (
    // biome-ignore lint/suspicious/noArrayIndexKey: These elements are static so key is ok.
    <Fragment key={ix}>
      {acc}
      {acc && <span>; </span>}
      {el}
    </Fragment>
  ));
}
