import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import CheckIcon from "@mui/icons-material/Check";
import clsx from "clsx";
import { type FocusEventHandler, useEffect, useState } from "react";

import type { AutocompleteOption } from "../AutocompleteInput";
import SimpleChipInput from "../Input/ChipInput/SimpleChipInput";
import { LabeledInputVariants } from "../Input/LabeledInput";
import Typography from "../Typography";
import type { LabeledInputFieldProps } from "./LabeledInputField";
import type { FieldLabelProps } from "./types";

export function ChipAutocomplete<T extends object>({
  values,
  options,
  onSelect,
  onChange,
  onChipClick,
  onBlur,
  renderValue,
  className,
  dataTestId,
  ...rest
}: FieldLabelProps & {
  className?: string;
  values: T[];
  options: AutocompleteOption<T>[];
  onSelect: (values: T[]) => void;
  onChange: (query: string) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChipClick: (ix: number) => void;
  renderValue: (value: T) => string;
  autoFocus?: boolean;
  message?: string;
  initialVariant?: LabeledInputVariants;
  dataTestId?: string;
}) {
  const [query, setQuery] = useState("");

  // biome-ignore lint/correctness/useExhaustiveDependencies: Call this only when query changes.
  useEffect(() => {
    onChange(query);
  }, [query]);

  return (
    <div className={clsx("relative", className)}>
      <Combobox
        multiple
        value={values}
        onChange={(values) => {
          onSelect(values);
          setQuery("");
        }}
        onClose={() => setQuery("")}
      >
        <ComboboxInput
          as={SimpleChipInput}
          value={query}
          values={values.map(renderValue)}
          onChange={(e) => setQuery(e.target.value)}
          onChipClick={onChipClick}
          onBlur={onBlur}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && values && !query.length) {
              onChipClick(values.length - 1);
            }
          }}
          dataTestId={dataTestId}
          {...rest}
        />
        <ComboboxOptions
          className="empty:hidden left-0 right-0 absolute z-1 bg-white rounded-xl max-h-[250px] overflow-y-scroll min-w-[300px] border border-solid border-cp-neutral-20 shadow-md"
          as="ul"
          modal={false}
        >
          {options.map((option) => (
            <ComboboxOption
              className="px-6 py-3 ui-active:bg-cp-white-200 cursor-pointer flex items-center w-full justify-between"
              key={option.id}
              value={option.data}
              data-testid={`${dataTestId}-${option.id}`}
              as="li"
            >
              <Typography>{option.label}</Typography>
              <CheckIcon className="hidden ui-selected:block h-4 w-4" />
            </ComboboxOption>
          ))}
        </ComboboxOptions>
      </Combobox>
    </div>
  );
}

export type ChipAutocompleteFieldProps<T extends object> = Omit<
  LabeledInputFieldProps<T[]>,
  "onChange"
> & {
  options: AutocompleteOption<T>[];
  onChange: (query: string) => void;
  renderValue: (value: T) => string;
  autoFocus?: boolean;
};

export default function ChipAutocompleteField<T extends object>({
  field,
  form: { setFieldValue, errors, touched },
  options,
  onChange,
  renderValue,
  editable: _editable, // TODO: support an uneditable state.
  ...rest
}: ChipAutocompleteFieldProps<T>) {
  const errorMessage = touched[field.name]
    ? (errors[field.name] as string)
    : undefined;

  return (
    <ChipAutocomplete
      {...rest}
      onSelect={(values) => setFieldValue(field.name, values)}
      onChange={onChange}
      onBlur={field.onBlur}
      values={field.value}
      options={options}
      renderValue={renderValue}
      onChipClick={(i) =>
        setFieldValue(
          field.name,
          field.value.filter((_, ix) => ix !== i)
        )
      }
      message={errorMessage}
      initialVariant={
        errorMessage ? LabeledInputVariants.ERROR : LabeledInputVariants.DEFAULT
      }
    />
  );
}
