import { TimelineEnum } from "../../generated/models/TimelineEnum";
import RadioSelectableChipsField, {
  type RadioSelectableChipsFieldProps,
  type RadioSelectableChipsOption,
} from "../../library/form/RadioSelectableChipsField";

export const TIMELINE_VALUES: Record<TimelineEnum, string> = {
  [TimelineEnum.IMMEDIATE]: "ASAP",
  [TimelineEnum._1_2_MONTHS]: "1-2 months",
  [TimelineEnum._3_5_MONTHS]: "3-5 months",
  [TimelineEnum._6_PLUS_MONTHS]: "6+ months",
  [TimelineEnum.NOT_APPLICABLE]: "I'm not planning to purchase",
};

const TIMELINE_OPTIONS: RadioSelectableChipsOption<TimelineEnum>[] = [
  TimelineEnum.IMMEDIATE,
  TimelineEnum._1_2_MONTHS,
  TimelineEnum._3_5_MONTHS,
  TimelineEnum._6_PLUS_MONTHS,
  TimelineEnum.NOT_APPLICABLE,
].map((value) => ({
  key: value,
  label: TIMELINE_VALUES[value],
  value,
}));

export default function TimelineOptionsField(
  props: Omit<RadioSelectableChipsFieldProps<TimelineEnum>, "options">
) {
  return (
    <RadioSelectableChipsField
      {...props}
      options={TIMELINE_OPTIONS}
      onChange={(value) => {
        if (value !== TimelineEnum.NOT_APPLICABLE) return;
        props.form.setFieldValue("supplierDecision", undefined);
        props.form.setFieldValue("suppliers", []);
      }}
    />
  );
}
