import { SupplierDecisionEnum } from "../../generated";
import type {
  RadioSelectableChipsFieldProps,
  RadioSelectableChipsOption,
} from "../../library/form/RadioSelectableChipsField";
import RadioSelectableChipsField from "../../library/form/RadioSelectableChipsField";

const SUPPLIER_DECISION_OPTIONS: RadioSelectableChipsOption<SupplierDecisionEnum>[] =
  [
    {
      key: SupplierDecisionEnum.DECIDED,
      label: "Yes, I have a supplier in mind",
      value: SupplierDecisionEnum.DECIDED,
    },
    {
      key: SupplierDecisionEnum.OPEN,
      label: "No, I don't have a supplier in mind",
      value: SupplierDecisionEnum.OPEN,
    },
  ];

export default function SupplierDecisionField(
  props: Omit<
    RadioSelectableChipsFieldProps<SupplierDecisionEnum>,
    "options" | "onChange"
  >
) {
  return (
    <RadioSelectableChipsField
      {...props}
      onChange={(value) => {
        if (value === SupplierDecisionEnum.OPEN) {
          props.form.setFieldValue("suppliers", [], false);
        }
      }}
      options={SUPPLIER_DECISION_OPTIONS}
    />
  );
}
