import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Field, Form, Formik } from "formik";
import type {
  BaseSupplier,
  CreatePurchaseIntentRequest,
} from "../../generated";
import { Button } from "../../library";
import usePurchaseIntentFields, {
  VALIDATION_SCHEMA,
} from "./usePurchaseIntentFields";

export type PurchaseIntentFormValues = Omit<
  CreatePurchaseIntentRequest,
  "suppliers"
> & {
  suppliers: BaseSupplier[];
};

function PurchaseIntentFields() {
  const fields = usePurchaseIntentFields();

  return (
    <>
      {fields.map((props) => (
        <Field key={props.name} editable {...props} />
      ))}
    </>
  );
}

// This shape is validated at runtime by fields and the validation schema.
const DEFAULT_VALUES = {
  query: "",
  timeline: undefined,
  supplierDecision: undefined,
  suppliers: [],
} as unknown as PurchaseIntentFormValues;

export function PurchaseIntentForm({
  onSubmit,
}: {
  onSubmit: (values: CreatePurchaseIntentRequest) => Promise<void>;
}) {
  return (
    <Formik
      onSubmit={({ suppliers, ...rest }) =>
        onSubmit({
          ...rest,
          suppliers: suppliers.map(({ id }) => id),
        })
      }
      initialValues={DEFAULT_VALUES}
      validateOnChange
      validateOnBlur={false}
      validationSchema={VALIDATION_SCHEMA}
    >
      <Form>
        <PurchaseIntentFields />
        <Button
          size={Button.sizes.LARGE}
          className="w-fit mt-6"
          type="submit"
          dataTestId="purchase-submit"
          badgeProps={{ Icon: SearchRoundedIcon }}
        >
          Search
        </Button>
      </Form>
    </Formik>
  );
}
