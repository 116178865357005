import { useField } from "formik";
import { useMemo } from "react";
import { SupplierDecisionEnum } from "../../generated/models/SupplierDecisionEnum";
import { TimelineEnum } from "../../generated/models/TimelineEnum";
import { LabelVariant } from "../../library";
import SupplierMultiselectField from "../../library/form/SupplierMultiselectField";
import type { FormFieldProps } from "../../library/form/types";
import { fieldValidationFn } from "../../library/form/utils";
import yup from "../../utils/yupPhone";
import OfferingAutocompleteField from "../PurchasePaths/OfferingAutocompleteField";
import SupplierDecisionField from "./SupplierDecisionField";
import TimelineOptionsField from "./TimelineOptionsField";

export const DEFAULT_PROPS: Partial<FormFieldProps> = {
  size: "sm",
  labelSize: "sm",
  labelStyle: LabelVariant.FLOATING_BLOCK,
  labelClassName: "mb-4",
  labelTextVariant: "body",
  labelEmphasis: true,
};

const PURCHASE_NEED_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  component: OfferingAutocompleteField,
  name: "query",
  label: "What are you looking for?",
  type: "text",
  placeholder: "Search products, services, or brands",
  validate: yup
    .string()
    .required(
      "Please tell us what you're searching for to help us find the right results."
    ),
  dataTestId: "purchase-query",
};

const TIMELINE_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  component: TimelineOptionsField,
  name: "timeline",
  label: "When are you purchasing?",
  validate: yup
    .string()
    .oneOf(Object.values(TimelineEnum))
    .required("Please select when you're planning to make this purchase."),
  className: "mt-6",
  dataTestId: "purchase-timeline",
};

const SUPPLIER_DECISION_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  component: SupplierDecisionField,
  name: "supplierDecision",
  label: "Do you have a supplier in mind?",
  sublabel: "We'll find options for working with them.",
  labelStyle: LabelVariant.FLOATING,
  validate: yup
    .string()
    .oneOf(Object.values(SupplierDecisionEnum))
    .when("timeline", {
      is: (value: Maybe<TimelineEnum>) =>
        !value || value === TimelineEnum.NOT_APPLICABLE,
      then: (rule) => rule.optional(),
      otherwise: (rule) =>
        rule.required(
          "Please let us know if you have any suppliers in mind for this purchase."
        ),
    }),
  className: "mt-6",
  dataTestId: "purchase-supplier-decision",
};

const SUPPLIER_MULTISELECT_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  component: SupplierMultiselectField,
  name: "suppliers",
  validate: yup.array().when("supplierDecision", {
    is: SupplierDecisionEnum.DECIDED,
    then: (rule) =>
      rule.min(
        1,
        "Please enter at least one supplier you're considering for this purchase."
      ),
    otherwise: (rule) => rule.optional(),
  }),
  className: "mt-3",
  placeholder: "Start typing a supplier's name to find them.",
  dataTestId: "purchase-suppliers",
};

export const VALIDATION_SCHEMA = fieldValidationFn([
  PURCHASE_NEED_FIELD,
  TIMELINE_FIELD,
  SUPPLIER_DECISION_FIELD,
  SUPPLIER_MULTISELECT_FIELD,
]);

export default function usePurchaseIntentFields() {
  const [timeline] = useField("timeline");
  const [supplierDecision] = useField("supplierDecision");

  return useMemo(() => {
    const fields = [PURCHASE_NEED_FIELD, TIMELINE_FIELD];
    if (!timeline.value || timeline.value === TimelineEnum.NOT_APPLICABLE)
      return fields;
    fields.push(SUPPLIER_DECISION_FIELD);
    if (
      !supplierDecision.value ||
      supplierDecision.value === SupplierDecisionEnum.OPEN
    )
      return fields;
    fields.push(SUPPLIER_MULTISELECT_FIELD);
    return fields;
  }, [timeline.value, supplierDecision.value]);
}
